<template>
  <div class="ele-body">
    <el-card shadow="never" class="card-box">
      <div slot="header" class="clearfix">
        <span>违禁词管理</span>
      </div>
      <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
        {{ tag }}
      </el-tag>
      
      <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
        @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 违禁词</el-button>
    </el-card>
  </div>
</template>



<script>
export default {
  name: "prohibited",
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: ''
    };
  },
  created() {
    this.setData()
  },
  methods: {
    setData() {
      let _this = this;
      this.$http.get('/Prohibited/getKeyWordList').then(res => {
        _this.dynamicTags = res.data.data;
      })

    },

    handleClose(tag) {
      let _this = this;
      _this.$http.post('/Prohibited/delProhibited', { 'prohibited_word': tag }).then(res => {
        //console.log('44444del', res.data);
        _this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      })


    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let _this = this;
      let inputValue = this.inputValue;
      if (inputValue) {
        _this.$http.post('/Prohibited/addProhibited', { 'prohibited_word': inputValue }).then(res => {
          //console.log('111rrttttgetKeyWordList', res.data);
          _this.dynamicTags.push(inputValue);
        })


      }
      this.inputVisible = false;
      this.inputValue = '';
    }

  }
}
</script>

<style>
.el-tag--medium{
  margin: 5px 10px 5px 0;
}
.el-tag+.el-tag {
  margin: 5px 10px 5px 0;
}
.el-button + .el-link, .el-button + .el-dropdown, .el-button + .ele-action, .el-link + .el-button, .el-link + .el-link, .el-link + .el-dropdown, .el-link + .ele-action, .el-dropdown + .el-button, .el-dropdown + .el-link, .el-dropdown + .el-dropdown, .el-dropdown + .ele-action, .ele-action + .el-button, .ele-action + .el-link, .ele-action + .el-dropdown, .ele-action + .ele-action, .el-tag + .el-tag{
  margin: 5px 10px 5px 0!important;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.card-box {
  min-height: 600px;
}

.tag{
  display: flex;
  flex-wrap: wrap;
}
</style>